import React from "react";
import { useSelector } from "react-redux";

import { defaultTabSettings } from "../../../reducers/settings/settingsSlice";

import { Button, Nav } from "react-bootstrap";

import { Input, RadioButton, Switch } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBorderAll, faExclamationCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";

import TabColorPicker from "./TabColorPicker";

// import EditTaskActions from "./EditTaskActions";

const General = ({ settings = {}, setSettings, setChangeMade, hideGrid = false, setSettingState }) => {
    const hadError = useSelector((state) => state?.settings?.error || false);

    const handleSettings = (newSettings, refetch = []) => {
        // setChangeMade(true);

        setSettingState((settingState) => {
            if (refetch.length > 0) {
                return { ...settingState, changeMade: true, refetch: [...refetch] };
            }

            return { ...settingState, changeMade: true };
        });

        setSettings(newSettings);
    };

    const handleChange = (key, value, refetch = []) => {
        const newSettings = {
            ...settings,
            [key]: value,
        };
        handleSettings(newSettings, refetch);
    };

    const handleFontChanges = (event) => {
        if (event.value === false) {
            const newSettings = {
                ...settings,
                gridFontSize: 14,
                gridCustomFontSize: false,
            };
            handleSettings(newSettings);
        } else {
            const newSettings = {
                ...settings,

                gridFontSize: 14,
                gridCustomFontSize: true,
            };
            handleSettings(newSettings);
        }
    };

    const handleTabChange = (type, key, value) => {
        const newSettings = {
            ...settings,
            tabs: {
                ...settings.tabs,
                [type]: {
                    ...settings.tabs[type],
                    [key]: value,
                },
            },
        };

        handleSettings(newSettings);
    };

    const resetTabs = () => {
        const newSettings = {
            ...settings,
            tabs: {
                grid: defaultTabSettings,
                add: defaultTabSettings,
                view: defaultTabSettings,
            },
        };

        handleSettings(newSettings);
    };

    const removeMoveToTab = () => {
        const newSettings = {
            ...settings,
            moveToTab: undefined,
        };

        handleSettings(newSettings);
    };

    const handleSwitchClick = (e) => {
        e.stopPropagation(); // Prevent the container's onClick from being triggered
    };

    const handleContainerClick = () => {
        const newSettings = {
            ...settings,
            gridFontSize: 14,
            gridCustomFontSize: !settings.gridCustomFontSize,
        };
        handleSettings(newSettings);
    };

    return (
        <div className="mb-5">
            <div>
                {hadError ? (
                    <div
                        style={{
                            border: "1px solid white",
                            backgroundColor: "rgba(255, 0 , 1, 0.1)",
                            borderRadius: "4px",
                        }}
                        className="p-3 d-flex my-3"
                    >
                        <FontAwesomeIcon icon={faExclamationCircle} size="lg" className="my-auto text-danger mx-3" />
                        <div className="my-auto">
                            There was an issue retrieving your settings. Any actions taken will not be saved.
                        </div>
                    </div>
                ) : null}
                <div className="fw-bold">General</div>
                <div className="ms-5">
                    <div className="mt-3 mb-4">
                        <Switch
                            id="enable-sticky-headers"
                            checked={settings?.stickyHeaders ?? true}
                            onChange={(event) => handleChange("stickyHeaders", event.value)}
                            size="small"
                        />
                        <span className="ms-2">Always show navigation tabs when scrolling</span>
                    </div>
                </div>
                <hr />

                <div className="my-2">
                    <div>
                        <b>Tabs</b>
                    </div>
                </div>
                <div className="ms-5">
                    <div className="mt-3 mb-4">
                        <Switch
                            id="automatically-close-tabs"
                            checked={settings?.closeTabsOnSubmit ? true : false}
                            onChange={(event) => handleChange("closeTabsOnSubmit", event.value)}
                            size="small"
                        />
                        <span className="ms-2">Automatically close tabs after submit</span>
                    </div>
                    <div className="mt-2 mb-4 d-flex flex-row">
                        After closing a tab:
                        <div className="ms-5">
                            <RadioButton
                                name="tab-settings-deleting"
                                checked={parseFloat(settings?.moveToTab) !== 0}
                                label="Select the next available tab"
                                onChange={removeMoveToTab}
                            />
                            <RadioButton
                                name="tab-settings-deleting"
                                value={"0"}
                                checked={parseFloat(settings?.moveToTab) === 0}
                                label="Return to the first tab (the main grid)"
                                onChange={(event) => handleChange("moveToTab", parseFloat(event.value))}
                                className="ms-4"
                            />
                        </div>
                    </div>

                    <div className="mt-3 mb-5" style={{ minHeight: "fit-content" }}>
                        <div className="d-flex flex-row">
                            <div>Tab Appearance</div>
                            <div className="ms-auto">
                                <Button className="btn-foliedge-outline btn-sm" onClick={resetTabs}>
                                    Reset to Default
                                </Button>
                            </div>
                        </div>
                        <div className="d-flex flex-row mt-4">
                            <div className="d-flex flex-column me-5">
                                <div>
                                    <Nav.Item
                                        style={{
                                            height: "41px",
                                            width: "165px",
                                            backgroundColor: settings?.tabs?.grid?.background,
                                        }}
                                    >
                                        <Nav.Link className="h-100" title={"Grid Tab"}>
                                            <div className="h-100 align-items-center justify-content-center d-flex">
                                                <span className="contain-tab">
                                                    <span className="me-auto">
                                                        <FontAwesomeIcon
                                                            icon={faBorderAll}
                                                            className="me-2"
                                                            style={{ color: settings?.tabs?.grid?.icon }}
                                                        />
                                                    </span>
                                                    <span>Grid Tab</span>
                                                    <span className="me-auto" style={{ visibility: "hidden" }}>
                                                        <FontAwesomeIcon icon={faBorderAll} />
                                                    </span>
                                                </span>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                </div>
                                <div style={{ width: "100%" }}>
                                    <TabColorPicker type="grid" onChange={handleTabChange} />
                                </div>
                            </div>
                            <div className="d-flex flex-column me-5">
                                <div>
                                    <Nav.Item
                                        style={{
                                            height: "41px",
                                            width: "165px",
                                            backgroundColor: settings?.tabs?.add?.background,
                                        }}
                                    >
                                        <Nav.Link className={`h-100`} title={"Add Tab"}>
                                            <div className="h-100 align-items-center justify-content-center d-flex">
                                                <span className="contain-tab">
                                                    <span className="me-auto">
                                                        <FontAwesomeIcon
                                                            icon={faPlus}
                                                            className="me-2"
                                                            style={{ color: settings?.tabs?.add?.icon }}
                                                        />
                                                    </span>
                                                    <span>Add Tab</span>
                                                    <span className="me-auto" style={{ visibility: "hidden" }}>
                                                        <FontAwesomeIcon icon={faPlus} />
                                                    </span>
                                                </span>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                </div>

                                <div style={{ width: "100%" }}>
                                    <TabColorPicker type="add" onChange={handleTabChange} />
                                </div>
                            </div>
                            <div className="d-flex flex-column">
                                <div>
                                    <Nav.Item
                                        style={{
                                            height: "41px",
                                            width: "165px",
                                            backgroundColor: settings?.tabs?.view?.background,
                                        }}
                                    >
                                        <Nav.Link className={`h-100`} title={"View Tab"}>
                                            <div className="h-100 align-items-center justify-content-center d-flex">
                                                <span className="contain-tab">
                                                    <span className="me-auto">
                                                        <FontAwesomeIcon
                                                            icon={faClipboard}
                                                            className="me-2"
                                                            style={{ color: settings?.tabs?.view?.icon }}
                                                        />
                                                    </span>
                                                    <span>View Tab</span>
                                                    <span className="me-auto" style={{ visibility: "hidden" }}>
                                                        <FontAwesomeIcon icon={faClipboard} />
                                                    </span>
                                                </span>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                </div>
                                <div style={{ width: "100%" }}>
                                    <TabColorPicker type="view" onChange={handleTabChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr />
            <div style={{ display: hideGrid ? "none" : "" }}>
                <div>
                    <b>Grid</b>
                </div>
                <div className="d-flex flex-column ms-5">
                    <div className="my-3">
                        <DropDownList
                            data={[
                                { name: "Default", value: "medium" },
                                { name: "High", value: "small" },
                            ]}
                            dataItemKey="value"
                            textField="name"
                            defaultValue={{ name: "Default", value: "medium" }}
                            style={{ width: "200px" }}
                            name="grid density selector"
                            id="grid-density-selector"
                            onChange={(event) => handleChange("gridDensity", event.value.value)}
                            label="Grid Density"
                            value={[
                                { name: "Default", value: "medium" },
                                { name: "High", value: "small" },
                            ].find((item) => item.value === settings?.gridDensity)}
                            size="small"
                        />
                    </div>
                    <div>
                        <Switch
                            id="grid-wrap-text"
                            checked={settings?.gridWrapHeaders ? true : false}
                            onChange={(event) => handleChange("gridWrapHeaders", event.value)}
                            size="small"
                        />
                        <span className="ms-2">Wrap grid column names</span>
                    </div>
                    <div className="my-3">
                        <Switch
                            id="grid-wrap-text"
                            checked={settings?.gridWrap ? true : false}
                            onChange={(event) => handleChange("gridWrap", event.value)}
                            size="small"
                        />
                        <span className="ms-2">Wrap text inside the grid</span>
                    </div>
                    <div className="d-flex flex-row align-items-center">
                        <div
                            onClick={handleContainerClick}
                            style={{ cursor: "pointer" }}
                            className="d-flex flex-row align-items-center"
                        >
                            <Switch
                                id="custom-grid-font-size"
                                checked={settings?.gridCustomFontSize ? true : false}
                                onChange={handleFontChanges}
                                onClick={handleSwitchClick}
                                size="small"
                            />
                            <label style={{ marginLeft: "0.5rem", cursor: "pointer", minWidth: "fit-content" }}>
                                Custom font size
                            </label>
                        </div>
                        {settings?.gridCustomFontSize ? (
                            <>
                                <Input
                                    value={settings?.gridFontSize}
                                    onChange={(event) => handleChange("gridFontSize", event.value)}
                                    type="number"
                                    style={{ maxWidth: "100px", maxHeight: "30px" }}
                                    defaultValue={14}
                                    min={8}
                                    max={22}
                                    className="ms-3"
                                />
                                <span className="mt-auto ms-1">px</span>
                            </>
                        ) : null}
                    </div>
                    <div className="my-3">
                        <Switch
                            id="enable-grid-grouping"
                            checked={settings?.gridGroup ? true : false}
                            onChange={(event) => handleChange("gridGroup", event.value)}
                            size="small"
                        />
                        <span className="ms-2">Enable grouping grid columns</span>
                    </div>
                    <div>
                        <Switch
                            id="enable-grid-prefix"
                            checked={settings?.gridPrefix ? true : false}
                            onChange={(event) =>
                                handleChange("gridPrefix", event.value, ["properties", "agreements", "transactions"])
                            }
                            size="small"
                        />
                        <span className="ms-2">Ignore common prefixes in property names</span>
                    </div>
                </div>
                <hr />
            </div>
        </div>
    );
};

export default General;
