import React, { useState, useEffect } from "react";

import DotLottie from "./DotLottie";

const LoadingLottie = () => {
    return (
        <div className="d-flex align-items-center justify-content-center">
            {/* <div className="lottie-loading" style={{ marginTop: "20vh", width: "25%" }}> */}
            <div style={{ marginTop: "20vh" }}>
                <DotLottie
                    src="https://asm-static-files.s3.amazonaws.com/blue-circle-loading.lottie"
                    autoplay
                    speed={1}
                    style={{ width: 350, height: 350 }}
                />
            </div>
        </div>
    );
};

const LoadingSuspense = ({ delay = 150 }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShow(true);
        }, delay);
        return () => {
            clearTimeout(timeout);
        };
    }, [delay]);

    return show ? <LoadingLottie /> : null;
};

export default LoadingSuspense;
