import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import { PusherProvider } from "../providers/Pusher";
import { ColorProvider } from "../providers/ColorProvider";

import SuperTokens from "supertokens-auth-react";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { EmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/emailpassword/prebuiltui";

import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Session, { SessionAuth } from "supertokens-auth-react/recipe/session";
import * as reactRouterDom from "react-router-dom";

import App from "../App";
import Header from "../common/components/Header";
import LoadingSuspense from "../common/components/LoadingSuspense";
import { GridFilesProvider } from "../providers/GridFilesProvider";
import { UserProvider } from "../providers/UserProvider";

const AdminRoutes = React.lazy(() => import("../pages/AdminView/AdminRoutes"));
const Debug = React.lazy(() => import("../common/components/Debug"));

const apiDomain = import.meta.env.REACT_APP_API_DOMAIN;
const websiteDomain = import.meta.env.REACT_APP_APP_DOMAIN;

SuperTokens.init({
    appInfo: {
        appName: "FoliEdge",
        apiDomain: apiDomain,
        websiteDomain: websiteDomain,
        apiBasePath: "/auth",
        websiteBasePath: "/auth",
    },
    recipeList: [
        EmailPassword.init({
            override: {
                apis: (originalImplementation) => {
                    return {
                        ...originalImplementation,
                        signUpPOST: undefined,
                    };
                },
            },
        }),
        Session.init(),
    ],
});

const Routes = () => {
    return (
        <Suspense fallback={<LoadingSuspense delay={1500} />}>
            <UserProvider>
                <PusherProvider>
                    <ColorProvider>
                        <GridFilesProvider>
                            <Switch>
                                {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [EmailPasswordPreBuiltUI])}

                                {!import.meta.env.MODE || import.meta.env.MODE === "development" ? (
                                    <Route exact path="/debug">
                                        <SessionAuth>
                                            <Debug />
                                        </SessionAuth>
                                    </Route>
                                ) : null}
                                <Route path="/admin">
                                    <SessionAuth>
                                        <Header />
                                        <AdminRoutes />
                                    </SessionAuth>
                                </Route>
                                <Route path="/:active_tab?">
                                    <SessionAuth>
                                        <App />
                                    </SessionAuth>
                                </Route>
                            </Switch>
                        </GridFilesProvider>
                    </ColorProvider>
                </PusherProvider>
            </UserProvider>
        </Suspense>
    );
};

export default Routes;
