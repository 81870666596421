import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUserSettings } from "../../common/api/UserAPI";

export function updateSettingsFunc(state, action) {
    state[action.payload.key] = action.payload.newSettings;
    return state;
}

export const getSettings = createAsyncThunk("data/getSettings", async () => {
    try {
        const response = await getUserSettings();
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
});
