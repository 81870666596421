import React from "react";
import * as ReactDOMClient from "react-dom/client";
import * as Sentry from "@sentry/react";
import { ErrorBoundary } from "@sentry/react";
import { SuperTokensWrapper } from "supertokens-auth-react";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import "@fontsource-variable/inter";
// Must import before any components, not after
import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

// import reportWebVitals from "./reportWebVitals";
import { store, persistor } from "./store.js";
import Routes from "./routes/routes";

// import CustomError from "./common/components/CustomError";
import ProfileError from "./common/components/ProfileError.jsx";

import "./index.scss";

TimeAgo.addDefaultLocale(en);

//...
console.log("Sentry Environment:", import.meta.env.REACT_APP_SENTRY_ENV);

if (import.meta.env.REACT_APP_SENTRY_ENV === "production" || import.meta.env.REACT_APP_SENTRY_ENV === "staging") {
    Sentry.init({
        dsn: "https://fc1c94d0a17eeedebc3c07ed19804ccd@o4506875293728768.ingest.us.sentry.io/4506875300282368",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost:3000", "demo.asmoffice.net", "asm-new.foliedge.com"],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        environment: import.meta.env.REACT_APP_SENTRY_ENV,
    });
}

const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);

root.render(
    <SuperTokensWrapper>
        <BrowserRouter>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ErrorBoundary fallback={<ProfileError />}>
                        <Routes />
                    </ErrorBoundary>
                </PersistGate>
            </Provider>
        </BrowserRouter>
    </SuperTokensWrapper>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
