import { createSlice } from "@reduxjs/toolkit";
import { getSettings, updateSettingsFunc } from "./settingsFunctions";

export const defaultTabSettings = {
    icon: "rgba(0, 0, 0, 1)",
    background: "rgba(255, 239, 165, 0.6)",
};

const initialDataSettings = {
    window: "3 months",
};

const initialGeneralSettings = {
    stickyHeaders: true,
    closeTabsOnSubmit: false,
    showFilteredColumnsAboveGrid: false,
    gridWrapHeaders: false,
    gridWrap: false,
    gridFontSize: 14,
    gridCustomFontSize: false,
    gridDensity: "medium",
    tabs: {
        grid: defaultTabSettings,
        add: defaultTabSettings,
        view: defaultTabSettings,
    },
    data: initialDataSettings,
};

const initialSettings = {
    general: { ...initialGeneralSettings },
    error: false,
};

export const settingsSlice = createSlice({
    name: "settings",
    initialState: {
        ...initialSettings,
    },
    reducers: {
        updateSettings: (state, action) => {
            updateSettingsFunc(state, action);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSettings.fulfilled, (state, action) => {
            if (action.payload.length === 0) {
                return state;
            }
            state.general = action.payload;
        });
        builder.addCase(getSettings.rejected, (state, action) => {
            console.error("[STORE], Issue getting user settings.", action?.error);
            state.error = true;
            // return state;
        });
    },
});

export const { updateSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
