import isEqual from "lodash/isEqual";
import { initialChangesMade } from "../../providers/initialState";

export function createViewFunc(state, action) {
    let brandNewView = {
        global: undefined,
        main: undefined,
        changesMade: { ...initialChangesMade },
    };

    state[action.payload.module].tabs[action.payload.idx].currentView = brandNewView;
    state[action.payload.module].tabs[action.payload.idx].title = brandNewView.name;
    state[action.payload.module].views[action.payload.viewModule].push(brandNewView);
}

export function updateSettingsFunc(state, action) {
    let updatedSettings = {
        ...state,
        [action.payload.module]: {
            ...state[action.payload.module],
            settings: {
                ...action.payload.newSettings,
            },
        },
    };

    return updatedSettings;
}

export function createFromViewFunc(state, action) {
    let currChanges = action.payload.changesMade;

    const brandNewView = {
        global: undefined,
        main: undefined,
        data: { ...currChanges },
        changesMade: { ...initialChangesMade },
    };

    state[action.payload.module].tabs[action.payload.idx].currentView = brandNewView;
    state[action.payload.module].tabs[action.payload.idx].title = brandNewView.name;
    state[action.payload.module].tabs[action.payload.idx].changesMade = initialChangesMade;
}

export function setViewFunc(state, action) {
    const setViews = {
        ...state,
        [action.payload.module]: {
            ...state[action.payload.module],
            views: [...action.payload.views],
            currentView: "",
        },
    };
    return setViews;
}

export function changeViewFunc(state, action) {
    const { value = "", title = "", id = null } = action.payload;
    let newTabs = state[action.payload.module].tabs;
    let tabIdx = newTabs.findIndex((t) => t.id === id);
    if (tabIdx !== -1) {
        newTabs[tabIdx].currentView = value;
        newTabs[tabIdx].title = title;
        state[action.payload.module].tabs = newTabs;
        return state;
    } else {
        return state;
    }
}

export function changeViewNameFunc(state, action) {
    let previousViewName = state[action.payload.module].tabs[action.payload.idx].currentView.name;
    let newCurrentView = {
        ...state[action.payload.module].tabs[action.payload.idx].currentView,
        name: action.payload.value,
    };

    let newGlobalViews;

    newGlobalViews = [...state[action.payload.module].views[action.payload.viewModule]];

    for (let i = 0; i < newGlobalViews.length; i++) {
        if (newGlobalViews[i].name === previousViewName) {
            newGlobalViews[i].name = action.payload.value;
            break;
        }
    }

    let newTabs2 = [...state[action.payload.module].tabs];
    newTabs2[action.payload.idx].currentView = { ...newCurrentView };
    newTabs2[action.payload.idx].title = newCurrentView.name;
    return {
        ...state,
        [action.payload.module]: {
            ...state[action.payload.module],
            views: { ...state[action.payload.module].views, [action.payload.viewModule]: [...newGlobalViews] },
            tabs: [...newTabs2],
        },
    };
}

export function deleteViewFunc(state, action) {
    let idxToDelete = state[action.payload.module].views[action.payload.viewModule].findIndex(
        (v) => v.name === action.payload.name
    );
    if (idxToDelete === -1) {
        return { ...state };
    } else {
        return {
            ...state,
            [action.payload.module]: {
                ...state[action.payload.module],
                views: {
                    ...state[action.payload.module].views,
                    [action.payload.viewModule]: state[action.payload.module].views[action.payload.viewModule].splice(
                        idxToDelete,
                        1
                    ),
                },
                currentView: state[action.payload.module].views[0],
            },
        };
    }
}

export function changesMadeFunc(state, action) {
    let newTabs4 = [...state[action.payload.module].tabs];
    newTabs4[action.payload.idx].changesMade = { ...action.payload.changes };
    let changesMadeReturn = {
        ...state,
        [action.payload.module]: {
            ...state[action.payload.module],
            tabs: [...newTabs4],
        },
    };
    return changesMadeReturn;
}

export function saveChangesFunc(state, action) {
    let changedData = action.payload.changesMade;
    let { group, sort, filter, take, skip } = changedData;
    let data = { group, sort, filter, skip, take };
    let changesMadeColumns = changedData.columns;
    let saveChangesTab = [...state[action.payload.module].tabs];
    let nameToChange = state[action.payload.module].tabs[action.payload.idx].currentView.name;

    saveChangesTab[action.payload.idx].currentView = {
        ...saveChangesTab[action.payload.idx].currentView,
        data: {
            ...saveChangesTab[action.payload.idx].currentView.data,
            ...data,
        },
        columns:
            changesMadeColumns.length > 0
                ? [...changesMadeColumns]
                : [...state[action.payload.module].tabs[action.payload.idx].currentView.columns],
    };
    saveChangesTab[action.payload.idx].changesMade = {
        ...initialChangesMade,
    };

    let changeToGlobalViews = [...state[action.payload.module].views[action.payload.viewModule]];

    for (let i = 0; i < changeToGlobalViews.length; i++) {
        if (changeToGlobalViews[i].name === nameToChange) {
            changeToGlobalViews[i].data = {
                ...changeToGlobalViews[i].data,

                ...data,
            };
            if (changesMadeColumns.length > 0) {
                changeToGlobalViews[i].columns = changesMadeColumns;
            } else {
                changeToGlobalViews[i].columns =
                    state[action.payload.module].tabs[action.payload.idx].currentView.columns;
            }
            break;
        }
    }
    let moduleSaveChanges = {
        ...state,
        [action.payload.module]: {
            ...state[action.payload.module],
            views: { ...state[action.payload.module].views, [action.payload.viewModule]: changeToGlobalViews },
            tabs: [...saveChangesTab],
        },
    };
    return moduleSaveChanges;
}

export function addTabFunc(state, action) {
    const viewComponents = {
        companies: "Company",
        businesses: "Company",
        contacts: "Contact",
        properties: "Property",
        agreements: "Agreement",
        payments: "Payment",
        tasks: "Task",
    };

    let { module, component, title, type } = action.payload;
    if (!component) {
        if (type === "add") {
            component = `add${viewComponents[module]}`;
        } else {
            component = `view${viewComponents[module]}`;
        }
    }

    const newTab = {
        id: state[module].tabCount + 1,
        component: component,
        componentProps:
            module === "payments" && type === "add"
                ? { ...action.payload.props, apiID: "" }
                : { ...action.payload.props },
        title: title ? title : `Tab ${state[module].tabCount + 1}`,
        type: type,
        ...(type === "grid" && {
            currentView: "",
        }),

        ...(type === "grid" && {
            changesMade: {
                filter: { logic: "and", filters: [] },
                group: [],
                skip: 0,
                sort: [],
                take: 20,
                columns: [],
            },
        }),
    };

    let tabDoesNotExist = true;

    if (type === "view") {
        tabDoesNotExist = state[module].tabs.findIndex((t) => isEqual(t.componentProps, action.payload.props)) === -1;
    }

    if (tabDoesNotExist) {
        state[action.payload.module].tabCount = state[module].tabCount + 1;
        state[action.payload.module].tabs.unshift(newTab);
        state[action.payload.module].eventKeys.unshift(`${component}-${state[module].tabCount + 1}`);
    }

    return state;
}

export function addTabsFunc(state, action) {
    const viewComponents = {
        companies: "Company",
        businesses: "Company",
        contacts: "Contact",
        properties: "Property",
        agreements: "Agreement",
        payments: "Payment",
        tasks: "Task",
    };
    const tabs = action.payload.tabs;
    const allowedTabs = [];

    tabs.forEach((tab, idx) => {
        let { module, component, title, type } = tab;

        if (!component) {
            if (type === "add") {
                component = `add${viewComponents[module]}`;
            } else {
                component = `view${viewComponents[module]}`;
            }
        }

        const newTab = {
            id: state[module].tabCount + idx + 1,
            component: component,
            componentProps: module === "payments" && type === "add" ? { ...tab.props, apiID: "" } : { ...tab.props },
            title: title ? title : `Tab ${state[module].tabCount + idx + 1}`,
            type: type,
            ...(type === "grid" && {
                currentView: "",
            }),

            ...(type === "grid" && {
                changesMade: {
                    filter: { logic: "and", filters: [] },
                    group: [],
                    skip: 0,
                    sort: [],
                    take: 20,
                    columns: [],
                },
            }),
        };

        let tabDoesNotExist = true;

        if (type === "view") {
            tabDoesNotExist = state[module].tabs.findIndex((t) => isEqual(t.componentProps, tab.props)) === -1;
        }

        if (tabDoesNotExist) {
            allowedTabs.push(newTab);
        }
    });

    if (allowedTabs.length > 0) {
        const newEventKeys = allowedTabs.map((tab) => {
            return `${tab.component}-${tab.id}`;
        });

        state[action.payload.module].tabCount = state[action.payload.module].tabCount + allowedTabs.length;
        state[action.payload.module].tabs = [...allowedTabs, ...state[action.payload.module].tabs];
        state[action.payload.module].eventKeys = [...state[action.payload.module].eventKeys, ...newEventKeys];
    }

    return state;
}

export function removeActiveTabFunc(state, action) {
    const removeActiveTab = {
        ...state,
        [action.payload.module]: {
            ...state[action.payload.module],
            activeTab: "",
        },
    };
    return removeActiveTab;
}

export function add404TabFunc(state, action) {
    const add404Tab = {
        ...state,
        [action.payload.module]: {
            ...state[action.payload.module],
            tabCount: state[action.payload.module].tabCount + 1,
            tabs: [
                ...state[action.payload.module].tabs,
                {
                    id: state[action.payload.module].tabCount + 1,
                    component: "FourOhFour",
                    title: `Tab ${state[action.payload.module].tabCount + 1}`,
                },
            ],
            eventKeys: [
                ...state[action.payload.module].eventKeys,
                `FourOhFour-${state[action.payload.module].tabCount + 1}`,
            ],
        },
    };
    return add404Tab;
}

export function deleteTabFunc(state, action) {
    const deleteTabStatus = [...state[action.payload.module].tabs];
    const deleteTabEventKeys = state[action.payload.module].eventKeys;

    let deletedTab = null;
    let removeEventkey = 0;
    let deletedActiveTab;

    for (let i = 0; i < deleteTabStatus.length; i++) {
        if (deleteTabStatus[i].id === action.payload.id) {
            deletedTab = deleteTabStatus.splice(i, 1);
        }
    }

    if (deletedTab && deletedTab?.length > 0) {
        deletedActiveTab = `${deletedTab[0].type}/${deletedTab[0]?.componentProps?.apiID || deletedTab[0].id}`;
        removeEventkey = state[action.payload.module].eventKeys.indexOf(
            `${deletedTab[0]["component"]}-${deletedTab[0]["id"]}`
        );
        deleteTabEventKeys.splice(removeEventkey, 1);
    }

    if (state[action.payload.module].activeTab === deletedActiveTab) {
        state[action.payload.module].activeTab = "grid/1";
    }

    state[action.payload.module].tabs = deleteTabStatus;
    state[action.payload.module].eventKeys = deleteTabEventKeys;
}

export function externalDeleteTabFunc(state, action) {
    const deleteTabStatus = [...state[action.payload.module].tabs];
    const deleteTabEventKeys = state[action.payload.module].eventKeys;

    let deletedTab = null;
    let removeEventkey = 0;

    for (let i = 0; i < deleteTabStatus.length; i++) {
        if (deleteTabStatus[i].id === action.payload.id) {
            deletedTab = deleteTabStatus.splice(i, 1);
        }
    }

    if (deletedTab && deletedTab?.length > 0) {
        removeEventkey = state[action.payload.module].eventKeys.indexOf(
            `${deletedTab[0]["component"]}-${deletedTab[0]["id"]}`
        );
        deleteTabEventKeys.splice(removeEventkey, 1);
    }

    state[action.payload.module].tabs = deleteTabStatus;
    state[action.payload.module].eventKeys = deleteTabEventKeys;
    state[action.payload.module].isDeleting = true;
}

export function changeTabNameFunc(state, action) {
    const allTabs = state[action.payload.module].tabs;
    if (action.payload.tab !== undefined) {
        allTabs[action.payload.tab].title = action.payload.name;
    } else {
        let idx = allTabs.findIndex((a) => a.id === action.payload.id);
        allTabs[idx].title = action.payload.name;
    }

    state[action.payload.module].tabs = allTabs;
}

export function addAndActiveFunc(state, action) {
    state[action.payload.module].activeTab = `${action.payload.type}/${action.payload.id}`;

    state[action.payload.module].eventKeys.unshift(
        `${action.payload.component}-${state[action.payload.module].tabCount + 1}`
    );

    state[action.payload.module].tabs.unshift({
        id: state[action.payload.module].tabCount + 1,
        component: action.payload.component,
        componentProps:
            action.payload.module === "payments" && action.payload.type === "add"
                ? { ...action.payload.props, apiID: "" }
                : { ...action.payload.props },
        title: action.payload.title ? action.payload.title : `Tab ${state[action.payload.module].tabCount + 1}`,
        type: action.payload.type,
        currentView: "",
        ...(action.payload.type === "grid" && {
            changesMade: {
                filter: { logic: "and", filters: [] },
                group: [],
                skip: 0,
                sort: [],
                take: 20,
                columns: [],
            },
        }),
    });

    state[action.payload.module].tabCount = state[action.payload.module].tabCount + 1;
}

export function setActiveTabFunc(state, action) {
    state[action.payload.module].activeTab = `${action.payload.type}/${action.payload.id}`;
    state[action.payload.module].isDeleting = false;
}

export function closeAllTabsFunc(state, action) {
    const newTabs = state[action.payload.module].tabs.filter((t) => t.permanent === true);
    const newEventKeys = newTabs.map((tab) => tab.eventKey);

    state[action.payload.module].tabs = newTabs;
    state[action.payload.module].activeTab = "grid/1";
    state[action.payload.module].tabCount = newTabs.length;
    state[action.payload.module].eventKeys = newEventKeys;
}
