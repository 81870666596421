import React, { useState, useEffect } from "react";

import Toast from "react-bootstrap/Toast";

const ErrorToast = ({ show = false, message = "Uh Oh! There was an error." }) => {
    const [visible, setVisible] = useState(false);

    const toggleShow = () => {
        setVisible((visible) => !visible);
    };

    useEffect(() => {
        if (show) {
            setVisible(true);
        }
    }, [show]);

    return (
        <div
            style={{
                position: "fixed",
                top: 0,
                right: 0,
                zIndex: 9999999,
            }}
        >
            <Toast
                show={visible}
                onClose={toggleShow}
                className="m-2 foliedge-toast-error"
                delay={9000}
                autohide
                animation={false}
                bg={"danger"}
            >
                <Toast.Header style={{ backgroundColor: "white" }}>
                    <strong className="me-auto">FOLIEDGE</strong>
                </Toast.Header>
                <Toast.Body className="text-white">{message}</Toast.Body>
            </Toast>
        </div>
    );
};

export default ErrorToast;
