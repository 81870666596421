import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import { Button as KRButton } from "@progress/kendo-react-buttons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAddressBook,
    faBuilding,
    faBuilding as farBuilding,
    faHandshake as farHandshake,
} from "@fortawesome/free-regular-svg-icons";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

const RecentSearchItem = ({ item, index, selected, handleToggle, onMouseEnter, scroll, removeFromRecent }) => {
    const ref = useRef(null);

    const handleRecent = () => {
        removeFromRecent(item);
    };

    useEffect(() => {
        if (!scroll && index === selected) {
            ref.current?.scrollIntoView({ block: "center", inline: "nearest" });
        }
    }, [scroll, index, selected]);

    return (
        <li
            id={`option-${index}`}
            className="mt-3 global-search-item"
            role="option"
            aria-selected={index === selected}
            key={index}
            onMouseEnter={onMouseEnter}
        >
            {item.type === "property" ? (
                <Link
                    to={`/properties/view/${item.PropertyId}`}
                    style={{ color: "black", textDecoration: "none" }}
                    onClick={handleToggle}
                    ref={ref}
                >
                    <div className="global-search-result d-flex flex-row align-items-center">
                        <div>
                            <FontAwesomeIcon icon={farBuilding} fixedWidth className="me-4" size="lg" />
                        </div>
                        <div className="d-flex flex-column">
                            <div className="global-search-result-name">{item.PropertyName}</div>

                            <div className="text-muted text-muted-small">Property</div>
                        </div>
                    </div>
                </Link>
            ) : null}
            {item.type === "agreement" ? (
                <Link
                    to={`/agreements/view/${item.ContractId}`}
                    style={{ color: "black", textDecoration: "none" }}
                    onClick={handleToggle}
                    ref={ref}
                >
                    <div className="global-search-result d-flex flex-row align-items-center">
                        <div>
                            <FontAwesomeIcon icon={farHandshake} fixedWidth className="me-4" size="lg" />
                        </div>
                        <div className="d-flex flex-column">
                            <div className="global-search-result-name">
                                {item.ClientName}{" "}
                                <span className="text-muted text-muted-small">({item.ContractDispId})</span>
                            </div>
                            <div className="text-muted text-muted-small">
                                {item.ProviderName} &bull; {item.ServiceCategory} &bull; {item.Service}
                            </div>
                        </div>
                    </div>
                </Link>
            ) : null}
            {item.type === "business" ? (
                <Link
                    to={`/businesses/view/${item.CompanyId}`}
                    style={{ color: "black", textDecoration: "none" }}
                    onClick={handleToggle}
                    ref={ref}
                >
                    <div className="global-search-result d-flex flex-row align-items-center">
                        <div>
                            <FontAwesomeIcon icon={faBriefcase} fixedWidth className="me-4" size="lg" />
                        </div>
                        <div className="d-flex flex-column">
                            <div className="global-search-result-name">{item.CompanyName}</div>
                            <div className="text-muted text-muted-small">{item.CompanyType}</div>
                        </div>
                    </div>
                </Link>
            ) : null}
            {item.type === "contact" ? (
                <Link
                    to={`/contacts/view/${item.ContactId}`}
                    style={{ color: "black", textDecoration: "none" }}
                    onClick={handleToggle}
                    ref={ref}
                >
                    <div className="global-search-result d-flex flex-row align-items-center">
                        <div>
                            <FontAwesomeIcon icon={faAddressBook} fixedWidth className="me-4" size="lg" />
                        </div>
                        <div className="d-flex flex-column">
                            <div className="global-search-result-name">{item.ContactName}</div>
                        </div>
                    </div>
                </Link>
            ) : null}
            <KRButton
                size="small"
                fillMode={"flat"}
                className="remove-search j-100"
                title="Remove"
                style={{ background: "transparent" }}
                onClick={handleRecent}
            >
                <svg width="20" height="20" viewBox="0 0 20 20">
                    <path
                        d="M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z"
                        stroke="currentColor"
                        fill="none"
                        fillRule="evenodd"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    ></path>
                </svg>
            </KRButton>
        </li>
    );
};

export default RecentSearchItem;
