import React from "react";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { useColor } from "../../providers/ColorProvider";

import { Avatar } from "@progress/kendo-react-layout";
import "./css/UserAvatar.css";

const UserAvatar = ({ className = "user-avatar-medium" }) => {
    const { accessTokenPayload = { userName: "" } } = useSessionContext();
    const { userName = "" } = accessTokenPayload;
    const { color } = useColor();

    let separateNames = "";
    let initials = "";

    if (userName) {
        separateNames = userName.split(" ");
        initials = `${separateNames[0][0]?.toUpperCase()}${separateNames[1][0]?.toUpperCase()}`;
    }

    return (
        <Avatar
            type="text"
            rounded="full"
            border={false}
            style={{ backgroundColor: color.background, color: color.text, userSelect: "none" }}
            className={className}
            title={userName}
        >
            <span title={userName}>{initials}</span>
        </Avatar>
    );
};

export default UserAvatar;
