import React, { useState, useEffect } from "react";
import { Drawer, DrawerContent, DrawerItem } from "@progress/kendo-react-layout";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faWrench } from "@fortawesome/free-solid-svg-icons";

import General from "../DemoPreview/GridSettings/General";
import Subscriptions from "./Subscriptions";

const CustomItem = ({ customIcon, text = "", ...props }) => {
    return (
        <DrawerItem {...props}>
            <div className="d-flex flex-row align-items-center">
                <FontAwesomeIcon icon={customIcon} className="me-3" />

                {text}
            </div>
        </DrawerItem>
    );
};

// const childMap = {
//     "/general": <General />,
//     "/subscriptions": <Subscriptions />,
// };

const childMap = {
    "/general": (props) => <General {...props} />,
    "/subscriptions": (props) => <Subscriptions {...props} />,
};

// const makeSetting = (section, props) => {
//     const form = childMap[section];
//     return React.cloneElement(form, {
//         ...props,
//     });
// };

const items = [
    {
        text: "General",
        customIcon: faWrench,
        selected: true,
        route: "/general",
    },
    {
        text: "Notifications",
        customIcon: faBell,
        selected: false,
        route: "/subscriptions",
    },
];

const AccountSettingsRouter = ({ module, initialSelected, settings, setSettings, setChangeMade }) => {
    const [selected, setSelected] = useState("/general");

    const onSelect = (e) => {
        setSelected(e.itemTarget.props.route);
    };

    useEffect(() => {
        if (initialSelected) {
            setSelected(initialSelected);
        }
    }, [initialSelected]);

    return (
        <div className="d-flex">
            <Drawer
                expanded={true}
                position={"start"}
                mode={"push"}
                mini={true}
                items={items.map((item) => ({ ...item, selected: item.route === selected }))}
                onSelect={onSelect}
                animation={false}
                item={CustomItem}
                style={{ flex: 1, minHeight: "66vh", height: "100%" }}
            >
                <DrawerContent className="mx-3">
                    {childMap[selected]({
                        module,
                        settings,
                        setSettings,
                        setChangeMade,
                        hideGrid: true,
                    })}
                </DrawerContent>
            </Drawer>
        </div>
    );
};

export default AccountSettingsRouter;
