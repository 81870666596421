import React, { useState, useEffect } from "react";

import "./css/ScrollProgress.css";

const ScrollProgress = (props) => {
    const [scroll, setScroll] = useState("0%");

    useEffect(() => {
        const scrollProgress = () => {
            const scrollPx = window.scrollY;
            const winHeightPx = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            const scrolled = `${(scrollPx / winHeightPx) * 100}%`;
            setScroll(scrolled);
        };

        window.addEventListener("scroll", scrollProgress);

        return () => window.removeEventListener("scroll", scrollProgress);
    }, []);

    return (
        <>
            {Number(scroll.replace("%", "")) > 7 && (
                <div className="scroll-progress-container">
                    <div className="sticky-header-scroll" style={{ width: scroll }} />
                </div>
            )}
            {props.children}
        </>
    );
};

export default ScrollProgress;
